<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un produit
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un produit
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label>Titre</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.titre"
                        :class="{ 'is-invalid': errors.titre }"
                      />
                      <span class="form-text text-danger" v-if="errors.titre">{{
                        errors.titre[0]
                      }}</span>
                    </div>
                    <div class="col-md-3">
                      <label>Partenaire</label>
                      <multiselect
                        v-model="form.partenaire"
                        :options="option.partners"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un type"
                        label="nom_partenaire"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setPartnerId()"
                        :class="{ 'is-invalid': errors.partenaire_id }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.partenaire_id"
                        >{{ errors.partenaire_id[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Prix réel</label>
                      <input
                        type="number"
                        step="0"
                        min="0"
                        class="form-control"
                        v-model="form.prix_reel"
                        :class="{ 'is-invalid': errors.prix_reel }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.prix_reel"
                        >{{ errors.prix_reel[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Pourcentage reduction</label>
                      <input
                        type="number"
                        @change="calculateOfferPrice()"
                        min="0"
                        class="form-control"
                        v-model="form.pourcentage_reduction"
                        :class="{ 'is-invalid': errors.pourcentage_reduction }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.pourcentage_reduction"
                        >{{ errors.pourcentage_reduction[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-3">
                      <label>Prix offre</label>
                      <input
                        type="number"
                        readonly
                        class="form-control"
                        v-model="form.prix_offre"
                        :class="{ 'is-invalid': errors.prix_offre }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.prix_offre"
                        >{{ errors.prix_offre[0] }}</span
                      >
                    </div>
                    <div class="col-md-1">
                      <label>Qté</label>
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="form.qte_stock"
                        :class="{ 'is-invalid': errors.qte_stock }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.qte_stock"
                        >{{ errors.qte_stock[0] }}</span
                      >
                    </div>
                    <div class="col-md-2">
                      <label>Pop?</label>
                      <select
                        name=""
                        class="form-control"
                        v-model="form.populaire"
                        id=""
                      >
                        <option value="1">Oui</option>
                        <option value="0">Non</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label for="">Tags</label>
                      <multiselect
                        v-model="form.tags"
                        :options="tags"
                        :multiple="true"
                        :taggable="true"
                        tag-placeholder="Ajouter un tag"
                        placeholder="Selectionner tag"
                        label="name"
                        track-by="id"
                      >
                      </multiselect>
                      <span class="form-text text-danger" v-if="errors.tags">{{
                        errors.tags[0]
                      }}</span>
                    </div>
                    <div class="col-md-3 mt-4">
                      <label class="">Ajouter image(s)</label>
                      <input
                        type="file"
                        @change="imageChange"
                        accept="image/*"
                        class="hidden form-control-file"
                        name="image"
                        ref="files"
                        multiple
                        max
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="">Description</label>
                      <textarea
                        class="form-control"
                        v-model="form.description"
                        cols="30"
                        rows="8"
                        placeholder="Un petit message de description"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-2">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-outline-primary btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary btn-secondary"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  name: "Form",
  components: {
    Multiselect
  },
  data() {
    return {
      initialize: "product/create",
      store: "product",
      method: "post",
      form: {},
      option: {
        partners: []
      },

      tag: [],
      images: [],
      videos: [],
      tags: [],
      documents: [],
      success: false,
      has_error: false,
      error: "",
      errors: {},
      isValidData: null
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "product/" + this.$route.params.id + "/edit";
      this.store = "product/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
        vm.tags = response.data.tags;
      });
    },
    setPartnerId() {
      this.form.partenaire_id = this.form.partenaire.id;
    },
    imageChange() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.images.push(this.$refs.files.files[i]);
      }
    },
    calculateOfferPrice() {
      if (this.form.pourcentage_reduction === "") {
        return (this.form.prix_offre = "");
      }
      this.form.prix_offre =
        this.form.prix_reel -
        (this.form.prix_reel * this.form.pourcentage_reduction) / 100;
    },
    save: function() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      let vm = this;
      let formData = new FormData();
      for (let i = 0; i < this.images.length; i++) {
        let file = vm.images[i];
        formData.append("images[" + i + "]", file);
      }
      for (let i = 0; i < this.videos.length; i++) {
        let file = vm.videos[i];
        formData.append("videos[" + i + "]", file);
      }
      for (let i = 0; i < this.documents.length; i++) {
        let file = vm.documents[i];
        formData.append("documents[" + i + "]", file);
      }
      let config = { headers: { "content-type": "multipart/form-data" } };
      Object.entries(this.form).forEach(([key, value]) => {
        formData.append(key, value);
        if (key === "tags") {
          formData.append(key, JSON.stringify(value));
        }
      });
      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }
      console.log(this.form);
      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  }
};
</script>

<style scoped></style>
